<template>
  <b-modal
    id="payment-method-selection-modal"
    v-model="isVisible"
    header-class="no-borders"
    hide-footer
    size="xl"
    :title="modalTitle"
  >
    <div v-if="paymentMethodErrors" class="method-failed">
      <banner
        :message="paymentMethodErrors"
        :variant="'failed'"
      />
    </div>
    <div class="compliance-service-container">
      <h5>
        Compliance Service
      </h5>
      <div class="compliance-filing-fees">
        <h6>
          {{ complianceFiling }}
        </h6>
        <p>
          {{ complianceServiceAmount }}
        </p>
      </div>
    </div>
    <hr>
    <select-payment-method
      class="m-0 p-0 border-0"
      :is-billing-active="isBillingActive"
      :payment-header-text="selectPaymentMethodCardHeaderText"
      @missingPaymentMethod="missingPaymentMethod"
      @closeSection="closeSection"
      @togglePanel="togglePanel"
      @add-method-result="paymentMethodResult"
    />
    <div
      v-if="showSubmitContainer"
      class="submit-payment-container"
    >
      <p class="small">
        By clicking 'Submit', I accept the <a class="link-primary" href="tosUrl" target="_blank">Terms and Conditions</a>
      </p>
      <button
        :class="submitButtonClass"
        aria-label="Submit Payment button"
        :disabled="hasSubmitted"
        @click="submitPaymentMethodSelection"
      >
        {{ submitButtonText }}
      </button>
      <payment-ssl />
    </div>
  </b-modal>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import TokenizerClient from '@/common/modules/tokenizer-client'

  export default {
    name: 'PaymentMethodSelectionModal',
    components: {
      Banner: () => import('@/components/shared/Banner.vue'),
      SelectPaymentMethod: () => import('@/components/shared/PaymentModals/SelectPaymentMethod.vue'),
      PaymentSsl: () => import('@/components/shared/PaymentSsl'),
    },
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      selectedComplianceEvent: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        alternateCheckoutButtonLabel: 'Submit',
        hasSubmitted: false,
        isVisible: false,
        isBillingActive: false,
        paymentMethodErrors: null,
        showSubmitRequest: false,
        tokenizerClient: new TokenizerClient(this.$store),
        selectPaymentMethodCardHeaderText: 'Auto Payment',
      }
    },
    computed: {
      ...mapGetters('checkout', [
        'selectedPaymentMethod',
        'processingCheckout',
      ]),
      ...mapGetters('website', [
        'newTosUrl',
      ]),
      companyName() {
        return this.selectedComplianceEvent?.company_name || ''
      },
      complianceFiling() {
        const jurisdiction = this.selectedComplianceEvent?.registration?.jurisdiction?.state_province_region || ''
        return `${jurisdiction} compliance filing`
      },
      complianceServiceAmount() {
        const cost = this.selectedComplianceEvent?.__cost || ''
        const stateFees = this.selectedComplianceEvent?.__stateFees || ''

        if(cost === 0 || cost === '') {
          return `$${stateFees} state fees`
        }

        return `$${cost} plus $${stateFees} state fees`
      },
      currentPaymentMethod() {
        return this.selectedPaymentMethod || null
      },
      missingPaymentMethod() {
        return (this.selectedPaymentMethod == null || this.selectedPaymentMethod.id === 'ADD')
      },
      modalTitle() {
        return `Compliance Service | ${this.companyName}`
      },
      showSubmitContainer() {
        if (this.currentPaymentMethod != null && !this.isBillingActive) this.closeSection()
        return this.showSubmitRequest
      },
      submitButtonText() {
        return this.hasSubmitted ? 'Submitting...' : 'Submit'
      },
      submitButtonClass() {
        return this.hasSubmitted ? 'disabled' : 'primary'
      },
    },
    watch: {
      visible() {
        this.isVisible = this.$props.visible
      },
    },
    async mounted() {
      await this.resetPaymentMethod()
      this.hasSubmitted = false
      this.loaded = true
    },
    async updated() {
      this.loaded = true
    },
    methods: {
      ...mapActions('paymentMethods', [
        'resetPaymentMethod',
      ]),
      paymentMethodResult(errors) {
        this.paymentMethodErrors = errors
      },
      closeSection() {
        this.isBillingActive = false
        this.showSubmitRequest = true
      },
      togglePanel() {
        this.isBillingActive = true
        this.showSubmitRequest = false
      },
      submitPaymentMethodSelection() {
        this.hasSubmitted = true
        this.$emit('payment-method-selected', this.currentPaymentMethod.id)
      },
    },
  }
</script>

<style lang="scss" scoped>
// Palette
$black1: #231F20;
$gray1: #A7A7A7;
$teal1: $ct-ui-color-10;
$teal2: $ct-ui-color-26;

// Typography
// Typography: Font-Weights
$font-weight-400: $ct-ui-font-weight-4;
$font-weight-600: $ct-ui-font-weight-6;

// Typography: Mixin
@mixin typography($font-size: 1.0em, $font-weight: $font-weight-400, $color: $black1) {
  font-family: "Open Sans", "Roboto", sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  color: $color;
}

// Buttons
// Buttons: Mixins
@mixin btn-hover($hover-color: $teal2, $color: white) {
  border-color: $hover-color;
  background: $hover-color;
  color: $color;
}

@mixin btn-core($padding, $hover-color) {
  @include typography($color: white);
  width: 100%;
  max-width: 300px;
  border-width: 0.0625em;
  border-style: solid;
  border-radius: 0.3125em;
  padding: $padding;
  margin: 0;
  outline: 0;
  line-height: 1.1;

  &:hover {
    @include btn-hover($hover-color: $hover-color);
  }
}

@mixin btn-default($primary-color, $hover-color, $padding: 0.5em 1.0em) {
  background: $primary-color;
  color: white;
  border-color: $primary-color;
  @include btn-core($padding, $hover-color);
}

// Buttons: Types
button {
  margin: 1.0em 0;
}

button.primary {
  @include btn-default(
    $primary-color: $teal1,
    $hover-color: $teal2
  );
}

button.disabled {
  @include btn-default(
    $primary-color: $gray1,
    $hover-color: $gray1
  );
}

// Typography: Elements
h5, h6, p {
  margin: 0;
  padding: 0;
}


h5 {
  @include typography(
    $font-size: 1.25em,
    $font-weight: $font-weight-600
  );
}

h6 {
  @include typography($font-size: 1.25em);
}

p {
  @include typography;
}

p.small {
  @include typography(
    $font-size: 0.75em,
    $font-weight: $font-weight-600
  );
}

// Component
#payment-method-selection-modal {

  .method-failed {
    margin-bottom: 1rem;
  }

  .compliance-service-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5em;
    padding-bottom: 1.0em;

    h5 {
      max-width: 264px;
      width: 100%;
    }

    .compliance-filing-fees {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
    }
  }

  .submit-payment-container {
    padding-top: 1.5em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    row-gap: 1.0em;
  }
}

// equivalent to medium or less in bootstrap
@media only screen and (max-width: 991px) {
  #payment-method-selection-modal {

    .compliance-service-container {
      flex-flow: column nowrap;
    }

    .submit-payment-container {
      padding-top: 0.5em;
    }
  }
}
</style>
